<template>
    <div class="exam-wrapper">
        <div class="exam-wizard__form">
            <div
                v-if="getterExamStatus === 'in_progress' && showCountdown"
                class="exam_details exam_details_wizard__test"
            >
                <h2>Fill in the blanks</h2>
                <div class="exam_details_wizard__test-info">
                    <div class="exam_details_item exam_details_wizard__test-info-questions">
                        <p class="exam_details_title">
                            Questions
                        </p>
                        <p class="exam_details_subtitle">
                            <b class="current-index">{{ currentIndex }}</b>/{{ getterQuestionsTotal }}
                        </p>
                    </div>
                    <div class="exam_details_item exam_details_item--countdown exam_details_wizard__test-info-countdown">
                        <p class="exam_details_title">
                            Time left
                        </p>
                        <img
                            src="@/assets/img/svg/clock-icon.svg"
                            style="margin: 0 10px"
                        >
                        <p class="exam_details_subtitle">
                            <b>{{ getterCountdown }}</b>
                        </p>
                    </div>
                </div>
            </div>
            <div class="loading-overlay">
                <ExamStart
                    v-if="getterExamStatus === 'start'"
                    @startExam="startExam"
                />
                <ExamTest
                    v-if="getterExamStatus === 'in_progress' && getterCurentQuestion"
                    ref="exam"
                    :current-question="getterCurentQuestion"
                    :current-answers="getterCurentAnswers"
                    :index="currentIndex"
                    :loading="getterSaveAnswerLoading"
                    @saveAnswer="saveAnswer"
                />
                <ExamFinish
                    v-if="getterExamStatus === 'finish'"
                />
                <div
                    v-if="isLoading"
                    class="loading-overlay-item"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus';

import ExamStart from '@/components/wizard/WizardExam/ExamStart';
import ExamTest from '@/components/wizard/WizardExam/ExamTest';
import ExamFinish from '@/components/wizard/WizardExam/ExamFinish';

import { createNamespacedHelpers, mapGetters } from 'vuex';
import {
    ACTION_START_EXAM_SESSION,
    ACTION_SAVE_ANSWER,
    ACTION_SET_STATUS,
    ACTION_GET_EXAM_STATUS,
    ACTION_GET_QUESTION
} from '@/store/modules/exam/action-types'

require('moment-countdown');

const { mapActions: mapExamActions } = createNamespacedHelpers('exam')

export default {
    components: {
        ExamStart,
        ExamTest,
        ExamFinish
    },
    props: {
        showCountdown: {
            type: Boolean,
            default: false
        }
    },
    metaInfo: {
        title: 'Online English Exam'
    },
    data() {
        return {
            isLoading: true
        }
    },
    computed: {
        ...mapGetters('exam',
            [
                'getterExamStatus',
                'getterCurentQuestion',
                'getterCurentAnswers',
                'getterQuestionsLeft',
                'getterQuestionsTotal',
                'getterExpires',
                'getterSaveAnswerLoading',
                'getterCountdown',
                'getterToken'
            ]),
        currentIndex() {
            return this.getterQuestionsTotal - this.getterQuestionsLeft + 1
        },
        headerInfo() {
            return {
                title: 'Online English Exam',
                className: 'header-content__documents',
                steps: [
                    {
                        title: 'Home',
                        url: '/'
                    },
                    {
                        title: 'Dashboard',
                        url: '/cp/account'
                    },
                    {
                        title: 'Documents',
                        url: '/cp/documents'
                    }
                ]
            }
        }
    },
    async created() {
        try {
            this.isLoading = true
            await this[ACTION_GET_EXAM_STATUS]()
            const checkReadyExam = this.getterExamStatus === 'in_progress' && (!this.getterToken || !this.getterCurentQuestion)
            if (checkReadyExam) {
                await this[ACTION_START_EXAM_SESSION]()
            }
        } catch (error) {
            eventBus.$emit('showSnackBar', error, 'error')
        } finally {
            this.isLoading = false
        }
    },
    async beforeDestroy() {
        if (this.getterQuestionsLeft > 0) {
            await this[ACTION_SET_STATUS]('start')
        }
    },
    methods: {
        ...mapExamActions([
            ACTION_START_EXAM_SESSION,
            ACTION_SAVE_ANSWER,
            ACTION_SET_STATUS,
            ACTION_GET_EXAM_STATUS,
            ACTION_GET_QUESTION
        ]),
        async startExam() {
            try {
                await this[ACTION_START_EXAM_SESSION]()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async saveAnswer(payload) {
            try {
                await this[ACTION_SAVE_ANSWER](payload)
                if (this.$refs.exam) {
                    console.log('if');
                    this.$refs.exam.curr_index += 1
                }
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        }
    }
}
</script>

<style lang="scss">
    .exam_details{
        display: flex;
        color: #fff;
        &_item {
            margin-right: 20px;
            &:last-child{
                margin-right: 0;
            }
            &--countdown{
                width: 220px;
            }
        }
        &_title{
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 10px;
        }
        &_subtitle{
            font-size: 20px;
            .current-index {
                color: #1161E0;
                font-weight: 700;
            }
            span {
                font-size: 12px;
            }
        }
    }
    .loading-overlay{
        position: relative;
        &-item{
            position: absolute;
            top: -21px;
            right: -41px;
            bottom: -21px;
            left: -41px;
            background: rgba(0,0,0,0.1);
            backdrop-filter: blur(5px);
        }
    }

    .exam_details_wizard{
        &__test{
            justify-content: space-between;
            align-items: center;
            padding: 40px;
            border-bottom: 2px solid #EAEAEA;
            flex-wrap: wrap;
            gap: 10px;
            h2 {
                font-size: 26px;
            }
        }
        &__test-info{
            display: flex;
            flex-wrap: wrap;
        }
        &__test-info-questions{
            display: flex;
            align-items: flex-end;
            margin-right: 50px;
            .exam_details_title{
                margin-bottom: 0;
                margin-right: 10px;
            }
        }
        &__test-info-countdown{
            display: flex;
            align-items: flex-end;
            width: 400px !important;
            .exam_details_title{
                margin-bottom: 0;
                white-space: nowrap;
            }
        }
    }
</style>
