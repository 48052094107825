<template>
    <div class="wizard-exam">
        <transition name="exam">
            <div
                v-if="curr_index === index"
                class="exam__card"
            >
                <div
                    v-if="$route.name === 'documents-exam'"
                    class="wizard__title"
                >
                    Fill in the blanks
                </div>
                <p class="exam-question">
                    {{ index }}.
                    <span v-html="currentQuestion.question" />
                </p>
                <div class="exam-answers">
                    <form-input-radio
                        v-for="answer in currentAnswers"
                        :key="answer.id"
                        :data="`${answer.id}`"
                        :text="answer.answer"
                        :name="`${currentQuestion.id}`"
                        :is-checked="answer.id === checked_answer"
                        @input="checked_answer = answer.id"
                    />
                </div>
            </div>
        </transition>
        <div class="wizard-content__buttons--exam">
            <custom-button
                :disabled="!checked_answer || loading"
                default
                :loading="loading"
                class="exam-btn"
                @on-btn-click="goToNextQuestion"
            >
                Next
            </custom-button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        currentQuestion: {
            type: Object,
            required: true
        },
        currentAnswers: {
            type: Array,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            checked_answer: null,
            curr_index: 1
        }
    },
    watch: {
        index() {
            this.checked_answer = null
        }
    },
    created() {
        this.curr_index = this.index
    },
    methods: {
        goToNextQuestion() {
            this.$emit('saveAnswer', {
                answer_id: this.checked_answer,
                question_id: this.currentQuestion.id
            })
        }
    }
}
</script>

<style lang="scss">
    .exam-question {
        margin-top: 10px;
        font-size: 18px;
        line-height: 30px;
        color: #181818;
        font-weight: 500;
    }
    .wizard__title {
        font-weight: bold;
        font-size: 24px;
        line-height: 40px;
        color: #181818;
        padding-bottom: 9px;
        border-bottom: 1px solid #dedede;
    }
    .exam-answers {
        margin-top: 20px;
        .form-radio {
            margin-top: 10px;
            .form-radio_label {
                align-items: center;
            }
        }
    }
    .exam__card {
        padding: 20px;
        background: #fff;
        //box-shadow: 0px 4px 16px -4px rgba(16, 24, 40, 0.28), 0px 2px 6px -2px rgba(16, 24, 40, 0.23);
        border-radius: 4px;
    }
    .exam-enter-active,
    .exam-leave-active {
        transition: all 1s;
        transform: translateX(0);
    }
    .exam-enter
    {
        opacity: 0;
        transform: translateX(100%);
    }
    .exam-leave-to
    {
        opacity: 0;
        transform: translateX(-100%);
    }
</style>
