<template>
    <div class="wizard-finish">
        <div class="wizard-finish__body">
            <img src="@/assets/img/svg/withard-finish.svg">
            <h2>Thanks for your answers!</h2>
            <p> Please wait for our reply </p>
        </div>
        <div class="wizard-exam__block wizard-content__buttons--exam">
            <router-link
                class="btn-base btn-main"
                tag="button"
                :disabled="isWriterApplicant"
                :to="{name: 'account'}"
            >
                Go to account
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('writer', [
            'getterWriterStatuses'
        ]),
        isWriterApplicant() {
            return this.getterWriterStatuses.accessstatus === 'APPLICANT'
        }
    }
}
</script>

<style lang="scss">
    .wizard-finish{
        display: flex;
        flex-direction: column;
        align-items: center;
        &__body{
            align-items: center;
            display: flex;
            flex-direction: column;
            padding: 60px;
        }
        img {
            margin-bottom: 20px;
        }
        h2 {
            margin-bottom: 20px;
            font-size: 22px;
        }
        p {
            margin-bottom: 20px;
        }
    }
    .wizard-content__buttons--exam{
        padding: 20px;
        display: flex;
        justify-content: center !important;
        border-top: 1px solid #EAEAEA;
        width: 100%;
    }
</style>
