<template>
    <div
        v-if="dialog"
        class="confirm-modal"
        @click.self="close()"
    >
        <div class="confirm-modal__dialog exam-modal__dialog">
            <header class="confirm-modal__header">
                <h2>English Exam</h2>
                <span
                    class="confirm-modal__close"
                    @click="close()"
                />
            </header>
            <section class="exam-modal__content">
                <div class="exam-modal__content-info">
                    <div class="exam-modal__content-info-code">
                        <qr-code
                            :text="qrCodeText"
                            size="220"
                            error-level="L"
                        />
                        <span class="exam-modal__timestamp">
                            {{ moment() }}
                        </span>
                    </div>
                    <div class="exam-modal__content-info-text">
                        <h2>Upload a selfie & start the test</h2>
                        <br>
                        <span>Make a selfie with your computer screen in the background (QR code must be clearly visible).</span>
                        <br>
                        <span>Then, upload the file and proceed with the exam.</span>
                        <br>
                        <file-uploader
                            :uploaded-files="uploadedFiles"
                            :max-file-size="8"
                            type="4"
                            loading-url="/api/file/applicant/upload"
                            @success="success"
                        />
                    </div>
                </div>

                <attachments-table
                    :uploaded-files="uploadedFiles"
                    :uploaded-files-own="uploadedFilesOwn"
                    delete-url="/api/file/applicant/delete"
                    @onDelete="deleteSuccess"
                />
            </section>
            <footer>
                <div class="confirm-modal__two-button-block exam-modal__btns">
                    <button
                        class="confirm-modal__two-button confirm-modal__two-button--gray"
                        @click.prevent="close()"
                    >
                        cancel
                    </button>
                    <button
                        class="confirm-modal__two-button confirm-modal__two-button--main"
                        :disabled="!uploadedFiles.length"
                        @click.prevent="submit()"
                    >
                        start
                    </button>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import FileUploader from '@/components/FileUploader.vue';
import AttachmentsTable from '@/components/AttachmentsTable.vue';
import VueQRCodeComponent from 'vue-qr-generator'

export default {
    name: 'ExamPhotoModal',
    components: {
        FileUploader,
        AttachmentsTable,
        qrCode: VueQRCodeComponent
    },
    data: () => ({
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        title: null,
        uploadedFiles: [],
        uploadedFilesOwn: []
    }),
    computed: {
        ...mapGetters('writer', [
            'getterMainWriterInfo'
        ]),
        qrCodeText() {
            return `firstname: ${this.getterMainWriterInfo.firstname}, lastname: ${this.getterMainWriterInfo.lastname}, id: ${this.getterMainWriterInfo.id}`
        }
    },
    methods: {
        moment,
        open(title, message) {
            this.dialog = true
            this.title = title
            this.message = message
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        submit() {
            this.resolve(true)
            this.dialog = false
        },
        close() {
            this.resolve(false)
            this.dialog = false
        },
        async success(files) {
            this.uploadedFilesOwn = files
            this.uploadedFiles.push(...files)
        },
        async deleteSuccess(file_id) {
            this.uploadedFiles = this.uploadedFiles.filter((item) => item.file_id !== file_id)
            this.checkType()
        }
    }
}
</script>

<style lang="scss">
.confirm-modal {
    position: fixed;
    overflow: hidden;
    background-color: rgba(11, 19, 31, .45);
    backdrop-filter: blur(3px);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    overscroll-behavior: contain;
    z-index: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    &__dialog {
        max-width: 600px;
        width: 100%;
        background-color: #fff;
        -webkit-box-shadow: 0 10px 40px rgba(25, 50, 71, .2);
        box-shadow: 0 10px 40px rgba(25, 50, 71, .2);
        border-radius: $input-border-radius;
        top: 90px;
        left: calc(50% - 250px);
        z-index: 999999999;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__header {
        font-weight: 500;
        color: $font-color-main;
        font-size: 24px;
        border-bottom: 1px solid $border-light;
        padding: 20px;
        position: relative;
    }
    &__close {
        position: absolute;
        right: 20px;
        top: 50%;
        min-width: 14px;
        width: 14px;
        height: 14px;
        background: url("~@/assets/img/svg/close.svg") no-repeat;
        background-size: contain;
        transform: translateY(-50%);
        color: #4F4F4F;
        cursor: pointer;
    }
    &__content {
        padding: 20px 20px 0;
        color: #757c89;
        font-size: 16px;
        line-height: 1.3;
    }
    &__two-button-block {
        display: flex;
        justify-content: space-between;
        padding: 20px;
    }
    &__one-button-block {
        display: flex;
        justify-content: center;
        padding: 20px;
        button {
            text-transform: capitalize;
        }
    }
    &__two-button {
        padding: 15px 0;
        width: calc(50% - 10px);
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        border: none;
        border-radius: $input-border-radius;
        cursor: pointer;
        &--gray {
            background-color: #f5f5f5;
            &:hover {
                background-color: rgba(145, 149, 154, 0.2);
                transition: 0.5s;
            }
        }
        &--main {
            background: #1976D2;
            color: white;
            &:disabled {
                color: #ccc !important;
                background: #f2f2f2 !important;
            }
            //&:hover {
            //    background: linear-gradient(94.79deg, #FF5925 0.59%, #FF9D39 98.53%), #1161E0;
            //    transition: 0.5s;
            //}
        }
    }
    &__one-button {
        padding: 20px 0;
        width: 80%;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        border: none;
        border-radius: $input-border-radius;
        cursor: pointer;
        &--main {
            background: linear-gradient(94.79deg, #FF9D39 0.59%, #FF5925 98.53%), #1161E0;
            box-shadow: 0 10px 20px -5px rgba(196,74,0,.6);
            color: white;
            //&:hover {
            //    background: linear-gradient(94.79deg, #FF5925 0.59%, #FF9D39 98.53%), #1161E0;
            //    transition: 0.5s;
            //}
        }

    }
}
.exam-modal {
    &__dialog {
        max-width: 1200px;
        //min-height: 600px;
        max-height: calc(100% - 50px);
        overflow-y: auto;
        overflow-x: hidden;
    }
    &__content {
        height: 100%;
        &-info {
            display: flex;
            width: 100%;
            justify-items: center;
            text-align: center;
            font-size: 20px;
            grid-template-columns: 1fr 1fr;
            border-bottom: 1px solid #e7ecf4;
            @media (max-width: 768px) {
                display: flex;
                flex-direction: column;
            }
            &-code {
                width: 40%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                border-right: 1px solid #e7ecf4;
                @media (max-width: 768px) {
                    width: 100%;
                    border-bottom: 1px solid #e7ecf4;
                    border-right: none;
                    padding: 40px 0;
                }
            }
            &-text {
                width: 60%;
                display: flex;
                flex-direction: column;
                padding: 40px 60px;
                @media (max-width: 768px) {
                    width: 100%;
                }
                span {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                }
                .file-uploader__box {
                    margin-top: auto;
                    margin-bottom: 0;
                }
            }
        }
        .file-uploader__box_uploaded {
            margin: 0;
            padding: 0 30px;
            .attachments-table
            .attachments-table__head
            .attachments-table__row
            .attachments-table__cell_avatar,
            .attachments-table__cell_type {
                display: none;
            }
            .attachments-table
            .attachments-table__body
            span
            .attachments-table__row
            .attachments-table__cell_avatar,
            .attachments-table__cell_type  {
                display: none;
            }
            .attachments-table
            .attachments-table__head
            .attachments-table__row {
                padding-bottom: 30px;
                border-bottom: 1px solid #e7ecf4;
            }
        }
    }
    &__timestamp {
        font-weight: 700;
        font-size: 20px;
        color: #181818;
        @media (max-width: 768px) {
            margin-top: 20px;
        }
    }
    &__btns {
        max-width: 600px;
        margin: 0 auto;
    }
}
@media (max-width: 480px) {
    .confirm-modal {
        & .confirm-modal__content {
            padding: 15px 15px 0;
        }
        & .confirm-modal__two-button-block {
            padding: 15px;
        }
    }
}
</style>
