<template>
    <div>
        <div class="exam-preview__head">
            <h1>Exam</h1>
        </div>
        <div class="exam-preview__body">
            <p class="exam-preview__text">
                <b>Revised on Nov 15, 2022</b>
                <span>
                    The goal of this exam is to help us measure your proficiency in the English language (sentence structure, grammar and vocabulary)
                    The results will be used by the management for decision
                    making purposes. The test is a mix of the following types of <i>multiple-choice</i> questions (may appear in random order):
                </span>
                <ul class="list">
                    <li>Grammar: make the sentence grammatically correct</li>
                    <li>Sentence structure: make the sentence structurally correct</li>
                    <li>Word choice: choose the word that fits the sentence the best</li>
                </ul>
            </p>
            <div
                v-for="(question, id) in getQuestions"
                :key="id"
                class="exam-preview__question"
            >
                <h3 class="exam-preview__question-title">
                    {{ question.title }}
                </h3>
                <p class="exam-preview__question-description">
                    {{ question.description }}
                </p>
                <p class="exam-preview__question-example">
                    <b>
                        Example:
                    </b>
                </p>
                <p class="exam-preview__question-text">
                    {{ id + 1 }}. {{ question.question }}
                </p>
                <p
                    v-for="(answer, answer_id) in question.answers"
                    :key="answer_id"
                    class="exam-preview__question-answer"
                >
                    {{ answer.value }}) {{ answer.text }}
                </p>
                <p class="exam-preview__question-final-answer">
                    <img src="@/assets/img/svg/attention-icon.svg">
                    <span>{{ question.answer }}</span>
                </p>
            </div>
        </div>
        <div class="wizard-content__buttons wizard-content__buttons--exam">
            <custom-button
                default
                class="exam-btn"
                @on-btn-click="openModal('confirm-modal')"
            >
                {{ getterExpires ? 'Start exam' : 'Begin' }}
            </custom-button>
        </div>

        <ExamPhotoModal ref="confirm-modal" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ExamPhotoModal from '@/components/wizard/WizardExam/ExamPhotoModal';

export default {
    name: 'WizardExamBegin',
    components: {
        ExamPhotoModal
    },
    computed: {
        ...mapGetters('exam',
            [
                'getterExpires'
            ]),
        getQuestions() {
            return [
                {
                    title: 'Example 1 : Complete the sentence',
                    description: 'This section is designed to measure your ability to recognize language that is appropriate for standard written English. Questions are incomplete sentences. Beneath each sentence you will see 4 words or phrases marked A, B, C, D. Choose the one word or phrase that best completes the sentence. Then mark the correct answer with your mouse and proceed to the next question.',
                    question: 'John Le Carre ______ for his novels concerning espionage.',
                    answers: [
                        {
                            value: 'a',
                            text: 'famous'
                        },
                        {
                            value: 'b',
                            text: 'has fame'
                        },
                        {
                            value: 'c',
                            text: 'is famous'
                        },
                        {
                            value: 'd',
                            text: 'famed for'
                        }
                    ],
                    answer: 'The sentence should read, "John Le Carre is famous for his novels concerning espionage." Therefore, you should choose answer C.'
                }
                // {
                //     title: 'Complete the sentence',
                //     description: 'This section is designed to measure your ability to recognize language that is appropriate for standard written English. Questions are incomplete sentences. Beneath each sentence you will see 4 words or phrases marked A, B, C, D. Choose the one word or phrase that best completes the sentence. Then mark the correct answer with your mouse and proceed to the next question.',
                //     question: 'John Le Carre ______ for his novels concerning espionage.',
                //     answers: [
                //         {
                //             value: 'a',
                //             text: 'famous'
                //         },
                //         {
                //             value: 'b',
                //             text: 'has fame'
                //         },
                //         {
                //             value: 'c',
                //             text: 'is famous'
                //         },
                //         {
                //             value: 'd',
                //             text: 'famed for'
                //         }
                //     ],
                //     answer: 'The sentence should read, "John Le Carre is famous for his novels concerning espionage." Therefore, you should choose answer C.'
                // }
            ]
        }
    },
    methods: {
        async openModal(name) {
            if (this.getterExpires) {
                this.startTest()
            } else if (await this.$refs[name].open()) {
                this.startTest()
            }
        },
        startTest() {
            this.$emit('startExam')
        }
    }
}
</script>

<style lang="scss">
.exam-wizard__form{
    max-width: 1200px;
    margin: 0 auto 40px;
    border: 1px solid #e7ecf4;
    box-shadow: 0 5px 30px rgb(0 0 0 / 20%);
    position: relative;
    background: white;
    margin-top: -40px !important;
    overflow: hidden;
    @media (max-width: 1200px){
        padding: 20px;
    }
}
.exam-preview {
    &__head{
        padding: 40px;
        border-bottom: 1px solid #EAEAEA;
        h1 {
            font-size: 24px;
        }
    }
    &__body{
        padding: 20px;
    }
    &__text {
        display: flex;
        flex-direction: column;
        font-size: 18px;
        line-height: 30px;
        color: #181818;
        b {
            font-size: 20px;
        }
        b, span {
            margin-bottom: 20px;
        }
        ul {
            list-style: disc;
            margin-left: 20px;
        }
    }
    &__question {
        font-size: 18px;
        line-height: 30px;
        color: #181818;
        &-title {
            font-size: 20px;
            line-height: 30px;
            color: #181818;
            margin: 20px 0;
        }
        &-description {
            margin-top: 10px;
        }
        &-example {
            margin: 20px 0;
            font-size: 20px;
        }
        &-text {
            margin-bottom: 20px;
        }
        &-final-answer {
            margin-top: 20px;
            background: #F2FBF2;
            padding: 10px 20px;
            display: flex;
            color: #1F2939;
            svg {
                width: 45px;
                height: 45px;
                margin-right: 15px;
            }
            span {
                padding: 0 20px;
            }
        }
    }
}
.exam-title{
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 56px;
    color: $white;
}

.wizard-content__buttons--exam{
    padding: 20px;
    display: flex;
    justify-content: center !important;
    border-top: 1px solid #EAEAEA;
}

@media (max-width: 550px){
    .exam-btn {
        width: 98%;
    }
}
</style>
